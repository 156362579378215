<template>
  <div>
    <leadCure/>
  </div>
</template>
<script>
import leadCure from './components/leadCure.vue'
export default {
  components: {
    leadCure
  },
  data () {
    return {

    }
  }
}
</script>
